import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, makeStyles } from '@material-ui/core';
import HomeContainer from '../../containers/home';
import React, { useEffect, useState } from 'react';
import { fetchClanInfo } from '../../store/clan/actions';
import {
  clanInfoSelector,
  isFetchingClanInfoSelector,
} from '../../store/clan/selectors';
import PageLoader from '../../components/page-loader';
import MemberListContainer from '../../containers/member-list';
import DialogRequestJoin from '../../containers/dialog-request-join';

const HomePage = ({ isFetching, clanInfo, fetchClanInfo }) => {
  const classes = useStyles();
  useEffect(() => {
    fetchClanInfo(process.env.REACT_APP_CLAN_TAG);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openDialog, setOpenDialog] = useState(false);

  if (isFetching) return <PageLoader />;

  return (
    <Box>
      <Helmet>
        <meta name="theme-color" content="#017acb" />
      </Helmet>
      <Box
        className={classes.boxBgImage}
        style={{ backgroundImage: `url('/statics/SL_043021_42650_02.jpg')` }}
      />
      {clanInfo && (
        <Box className={classes.container} position="relative">
          <HomeContainer setOpenDialog={setOpenDialog} clanInfo={clanInfo} />
          <MemberListContainer />
          <DialogRequestJoin
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
          />
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {},
  boxBgImage: {
    height: 500,
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      height: 540,
    },
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
  },
  logo: {
    width: 125,
    height: 125,
  },
  descriptionContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 460,
    },
  },
  description: {
    lineHeight: 1.75,
  },
}));

HomePage.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  clanInfo: PropTypes.object.isRequired,
  fetchClanInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  clanInfo: clanInfoSelector(state),
  isFetching: isFetchingClanInfoSelector(state),
});

const mapDispatchToProps = {
  fetchClanInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
