import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clanLogoSelector, clanNameSelector } from '../../store/clan/selectors';

const ClanLogo = ({ logo, title, classes }) => {
  if (logo) return <img className={classes} src={logo} alt={title} />;
  return (
    <img
      className={classes ? classes : {}}
      src="/statics/ftTkAMYbItnp1KTRcUprvNWBipA112tjWGj8K63kt8s.png"
      alt={title}
    />
  );
};

ClanLogo.propTypes = {
  logo: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.object,
};

ClanLogo.defaultProps = {
  title: 'Crunch Squad',
};

const mapStateToProps = (state) => ({
  logo: clanLogoSelector(state),
  title: clanNameSelector(state),
});

export default connect(mapStateToProps)(ClanLogo);
