import { colors } from '@material-ui/core';

export default {
  background: {
    dark: '#F4F6F8',
    default: '#ffcef8',
    paper: colors.common.white,
  },
  primary: {
    light: '#FFB9EF',
    main: '#F951FF',
    dark: '#9A28B7',
    contrastText: '#fff',
  },
  secondary: {
    light: '#A1D0FF',
    main: '#166FFF',
    dark: '#1055DB',
    contrastText: '#fff',
  },
  green: {
    light: '#51ef89',
    main: '#00b13f',
    dark: '#008630',
    contrastText: '#fff',
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
  },
};
