import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { createRoleName } from '../../utils';

const CardMemberItem = ({ player }) => {
  const classes = useStyles();

  return (
    <RouterLink
      className={classes.rootLink}
      to={`/player/` + player?.tag.replace('#', '')}
    >
      <Card className={classes.root} elevation={0}>
        <CardContent>
          <Box textAlign="center">
            <Box mt={1}>
              <Typography
                className={classes.pos}
                color="textSecondary"
                variant="subtitle2"
              >
                {createRoleName(player?.role)}
              </Typography>
            </Box>
            {player?.league?.iconUrls?.medium ? (
              <img
                className={classes.icon}
                src={player?.league?.iconUrls?.medium}
              />
            ) : (
              <img
                className={classes.icon}
                src={player?.league?.iconUrls?.small}
              />
            )}

            <Typography variant="h5" component="h2">
              {player?.name}
            </Typography>

            <Box mt={1}>
              <Typography
                className={classes.title}
                color="textSecondary"
                component="subtitle2"
                gutterBottom
              >
                {player?.tag}
              </Typography>
            </Box>
            <Box mt={1}>
              <Box mb={1}>
                <Typography variant="body2" component="p">
                  {player?.league?.name}
                </Typography>
              </Box>
              <Box className={classes.trophies}>
                <Typography variant="body2" component="p">
                  {player?.trophies}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </RouterLink>
  );
};

const useStyles = makeStyles((theme) => ({
  rootLink: {
    textDecoration: 'none',
    color: 'unset',
  },
  root: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  icon: {
    width: 72,
    height: 'auto',
  },
  trophies: {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 20,
    width: 100,
    margin: 'auto',
  },
}));

CardMemberItem.propTypes = {
  player: PropTypes.object.isRequired,
};

export default CardMemberItem;
