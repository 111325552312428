import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Box from '@material-ui/core/Box';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import FlashOnIcon from '@material-ui/icons/FlashOn';

const PlayerSpells = ({ spells }) => {
  const classes = useStyles();

  return (
    <Box mb={6}>
      <Box textAlign="center" mb={2}>
        <Typography component="h4" variant="h5">
          Spells
        </Typography>
      </Box>
      <List className={classes.root}>
        {Array.isArray(spells) && spells.length > 0
          ? spells.map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <FlashOnIcon />
                </ListItemIcon>
                <ListItemText primary={item.name} />
                <ListItemSecondaryAction>
                  <Box className={classes.level}>
                    <Typography component="span" variant="subtitle2">
                      Level: {item.level}
                    </Typography>
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          : null}
      </List>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listIcon: {
    color: theme.palette.secondary.main,
    backgroundColor: '#dde3ff !important',
  },
  level: {
    backgroundColor: '#5b03ea',
    borderRadius: 20,
    margin: '0 auto',
    color: '#fbfbfb',
    width: 80,
    textAlign: 'center',
  },
}));

PlayerSpells.propTypes = {
  spells: PropTypes.array,
};

export default PlayerSpells;
